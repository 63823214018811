import React, { useCallback, useContext, useLayoutEffect, useState } from 'react';
import LocaleContext from '../../context/Locale';
import { MenuItem } from '../MenuItem';
import styled, { css } from 'styled-components';
import Logo from '../../../static/icons/logo-nav.svg';
import PhoneIcon from '../../../static/icons/call_black_24dp.svg';
import EmailIcon from '../../../static/icons/email_black_24dp.svg';
import ScheduleIcon from '../../../static/icons/schedule_black_24dp.svg';
import { Container } from '../Container/Container';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { pxToRem } from '../../utils/pxToRem';
import { easeInOut, rem } from 'polished';
import { MenuMobile } from '../MenuMobile/MenuMobile';
import { PageRow } from '../PageRow/PageRow';
import { useLocation } from '@reach/router';
import { ROUTES } from '../../utils/routes';
import { Query } from '../../model/generated/graphql';

const variants = {
    open: {
        display: 'block',
        transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
        transition: {
            staggerChildren: 0.05,
            staggerDirection: -1,
        },
        display: 'none',
    },
};

const menu = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
};

export const Navigation = () => {
    const { activeLocale, updateLocale } = useContext(LocaleContext);
    const { contentfulPageKontakt: contact }: Query = useStaticQuery(graphql`
        query ContactHookQuery($node_locale: String) {
            contentfulPageKontakt(contentful_id: { eq: "4CI60KPsDdc3LGC7S3xH9k" }, node_locale: { eq: $node_locale }) {
                title
                companyName
                city
                street
                zipCode
                email
                phone1Label
                phone1Number
                phone2Label
                phone2Number
                phone3Label
                phone3Number
                openingHoursLabel
                openingHoursValue
                location {
                    lat
                    lon
                }
            }
        }
    `);

    const { pathname } = useLocation();

    const [open, toogleOpen] = useState(false);
    const [menuSticky, setMenuSticky] = useState(false);

    const setSticky = (e) => {
        const scrollTop = window.scrollY;
        scrollTop > 100 ? setMenuSticky(true) : setMenuSticky(false);
    };

    const menuPosition = useCallback(() => {
        window.requestAnimationFrame(setSticky);
    }, []);

    useLayoutEffect(() => {
        window.addEventListener('scroll', menuPosition);
    }, []);

    return (
        <div>
            <Topbar>
                <Container layout={{ display: 'flex', justifyContent: 'flex-end' }} mobile>
                    <TopbarItemWrapper hideMobile={true}>
                        <TopbarItem>
                            <ScheduleIcon width={18} fill="white" /> {contact.openingHoursValue}
                        </TopbarItem>
                    </TopbarItemWrapper>
                    <TopbarItemWrapper>
                        <TopbarLink href={`tel:+48${contact.phone1Number.split(' ').join('')}`}>
                            <PhoneIcon width={18} fill="white" />
                            <HideLabelOnMobile>{contact.phone1Number}</HideLabelOnMobile>
                        </TopbarLink>
                    </TopbarItemWrapper>
                    <TopbarItemWrapper>
                        <TopbarLink href={`mailto:${contact.email}`}>
                            <EmailIcon width={18} fill="white" />
                            <HideLabelOnMobile>{contact.email}</HideLabelOnMobile>
                        </TopbarLink>
                    </TopbarItemWrapper>
                    <TopbarItemWrapper>
                        <TopbarItem>
                            <button
                                className="btn-mydr-pp"
                                data-doctor=""
                                data-speciality=""
                                data-visitkind=""
                                data-evisit="false"
                                data-appname="drw"
                                data-token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmYWNpbGl0eV9pZCI6ODU3N30.2LCjgzbBITl06kc5exgkLYg7E8n0V-quZZMO5ozn8hw"
                                style={{
                                    width: 'unset',
                                    height: 'unset',
                                    background: 'none',
                                    color: 'white',
                                    outline: 'none',
                                    border: 'none',
                                    fontWeight: 500,
                                }}
                            >
                                Umów wizytę
                            </button>
                        </TopbarItem>
                    </TopbarItemWrapper>
                </Container>
            </Topbar>
            <MainMenu sticky={menuSticky}>
                <Container>
                    <PageRow>
                        <Navbar>
                            <Link to={'/'}>
                                <Logo width={250} />
                            </Link>

                            <NavList sticky={menuSticky}>
                                {itemIds.map((i) => (
                                    <MenuItem item={i} key={i.name} active={pathname.includes(i.slug)} />
                                ))}
                            </NavList>
                        </Navbar>
                    </PageRow>
                </Container>
            </MainMenu>
            <MenuMobile open={open} itemIds={itemIds} />
        </div>
    );
};

const MainMenu = styled.div<{ sticky: boolean }>`
    position: fixed;
    top: ${pxToRem(40)};
    z-index: 9998;
    width: 100%;
    background-color: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.1);
    @media (max-width: ${pxToRem(1000)}) {
        position: relative;
    }
`;

const Topbar = styled.div`
    width: 100%;
    background-color: var(--color-primary);
    color: var(--color-white);
    display: flex;
    align-content: flex-end;
    position: fixed;
    z-index: 9999;
    min-height: 2.5rem;
`;

const BooksyLabel = styled.div`
    @media (max-width: ${pxToRem(700)}) {
        display: none;
    }
`;

const TopbarItemStyles = css`
    font-size: 0.8rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: var(--color-white);
    padding: ${rem(8)} ${rem(12)};
    & svg {
        margin-right: ${pxToRem(6)};
    }
`;

export const TopbarLink = styled.a`
    transition: background-color ${easeInOut('quad')} 300ms;
    ${TopbarItemStyles};
    :hover {
        background-color: var(--color-secondary);
    }
`;

const HideLabelOnMobile = styled.span`
    @media (max-width: 600px) {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
        clip: rect(1px, 1px, 1px, 1px);
        white-space: nowrap; /* added line */
        left: -9999px;
    }
`;

const TopbarItem = styled.span`
    ${TopbarItemStyles}
`;

type TopbarItemWrapperProps = {
    hideMobile?: boolean;
};

const TopbarItemWrapper = styled.div<TopbarItemWrapperProps>`
    display: inline-flex;
    ${(props) => {
        if (props.hideMobile) {
            return css`
                @media (max-width: 800px) {
                    display: none;
                }
            `;
        }
    }}
`;

const Navbar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const NavList = styled.ul<{ sticky: boolean }>`
    padding: ${(props) => (props.sticky ? `${rem(12)} 0` : `${rem(25)} 0`)};
    margin: ${(props) => (props.sticky ? `0 ${rem(-16)} 0 0` : `${rem(25)} 0`)};
    //margin-right: ${rem(-16)};
    list-style: none;
    display: flex;
    transition: margin 200ms ease-in-out, padding 200ms ease-in-out;
    flex-wrap: wrap;

    @media (max-width: ${rem(1280)}) {
        margin: 0;
    }

    @media (max-width: ${rem(1000)}) {
        display: none;
    }
`;

export type IMenuItem = {
    name: string;
    slug: string;
};

const itemIds = [
    {
        name: 'Aktualności',
        slug: ROUTES.NEWS,
    },
    {
        name: 'Specjaliści',
        slug: ROUTES.TEAM,
    },
    {
        name: 'Rehabilitacja',
        slug: ROUTES.REHABILITACJA,
    },
    {
        name: 'Cennik',
        slug: ROUTES.CENNIK,
    },

    {
        name: 'Kontakt',
        slug: ROUTES.KONTAKT,
    },
];
