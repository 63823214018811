import React, { useEffect, useState } from 'react';
import { Normalize } from 'styled-normalize';
import styled, { createGlobalStyle } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { Navigation } from '../Navigation/Navigation';
import { LocaleProvider } from '../../context/Locale';
import { Footer } from '../Footer';
import Modal from 'react-modal';
import { pxToRem } from '../../utils/pxToRem';
import { VisuallyHidden } from '../../../.cache/fast-refresh-overlay/components/overlay';
import FontSizeIcon from '../../../static/icons/font-size.svg';
import { Chip } from '../../components/Chip';

const GlobalStyle = createGlobalStyle`
  html {
    --color-white: #fff;
    --color-primary:#36355D;
    --color-secondary:#00A69C;
    --color-secondary-dark:#018078;
    --color-text-primary: var(--color-primary);
    --color-text-secondary: var(--color-secondary);
    --color-text-common: #212121;
    --color-text-white: var(--color-white);
    --gray-light: #f5f5f5;
    --background-primary: var(--color-white);
    --swiper-pagination-color: var(--color-primary);
    --root-font-size: 16px;
    font-size:var(--root-font-size);
    
  }
  body {
    background-color: var(--background-primary);
    color: var(--color-text-primary);
    font-family: "DM Sans",sans-serif;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  a {
    text-decoration: none;
  }
  h1,h2,h3,h4 {
    font-weight: 700;
  }
  p {
    line-height: 1.4;
  }

  #gatsby-focus-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;
    z-index: 1;
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;
  }
  .ReactModal__Body--open {
    overflow: hidden;
  }
  .gm-style-cc {
    display: none;
  }
  img[alt="Google"] {
    display: none;
  }
  .tooltip {
    max-width: 280px;
  }
  .btn-nav {
      -webkit-tap-highlight-color: transparent;
  }

  /* dm-sans-regular - latin-ext_latin */
  @font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url('/fonts/dm-sans/dm-sans-v11-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/dm-sans/dm-sans-v11-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/dm-sans/dm-sans-v11-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/dm-sans/dm-sans-v11-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/fonts/dm-sans/dm-sans-v11-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/dm-sans/dm-sans-v11-latin-ext_latin-regular.svg#DMSans') format('svg'); /* Legacy iOS */
  }
  /* dm-sans-500 - latin-ext_latin */
  @font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-display: block;
    src: url('/fonts/dm-sans/dm-sans-v11-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/dm-sans/dm-sans-v11-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/dm-sans/dm-sans-v11-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/dm-sans/dm-sans-v11-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
    url('/fonts/dm-sans/dm-sans-v11-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/dm-sans/dm-sans-v11-latin-ext_latin-500.svg#DMSans') format('svg'); /* Legacy iOS */
  }
  /* dm-sans-700 - latin-ext_latin */
  @font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-display: block;
    src: url('/fonts/dm-sans/dm-sans-v11-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/dm-sans/dm-sans-v11-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/dm-sans/dm-sans-v11-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/dm-sans/dm-sans-v11-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
    url('/fonts/dm-sans/dm-sans-v11-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/dm-sans/dm-sans-v11-latin-ext_latin-700.svg#DMSans') format('svg'); /* Legacy iOS */
  }

`;

Modal.setAppElement('#___gatsby');

const Layout: ({
    children,
    location,
    pageResources,
    pageContext: { node_locale },
}: {
    children: any;
    location: any;
    pageResources: any;
    pageContext: { node_locale: any };
}) => JSX.Element = ({
    children,
    location,
    pageResources,
    //@ts-ignore
    pageContext: { node_locale },
}) => {
    const data = useStaticQuery(graphql`
        query HeaderQuery($node_locale: String) {
            contentfulPageKontakt(contentful_id: { eq: "4CI60KPsDdc3LGC7S3xH9k" }, node_locale: { eq: $node_locale }) {
                ...contact
            }
        }
    `);

    const [fontSize, setFontSize] = useState(16);

    // Function to increase the font size
    const increaseFontSize = () => {
        const newFontSize = Math.min(fontSize + 2, 30); // Ensure max size is 30px
        setFontSize(newFontSize);
        document.documentElement.style.setProperty('--root-font-size', `${newFontSize}px`);
    };

    // Function to decrease the font size
    const decreaseFontSize = () => {
        const newFontSize = Math.max(fontSize - 2, 16); // Ensure min size is 16px
        setFontSize(newFontSize);
        document.documentElement.style.setProperty('--root-font-size', `${newFontSize}px`);
    };

    const resetFontSize = () => {
        const newFontSize = 16; // Ensure min size is 16px
        setFontSize(newFontSize);
        document.documentElement.style.setProperty('--root-font-size', `${newFontSize}px`);
    };

    const [toolbarOpen, setToolbarOpen] = React.useState(false);

    const handleToolBarOpen = () => {
        setToolbarOpen(!toolbarOpen);
    };

    return (
        <>
            <LocaleProvider node_locale={node_locale} location={location}>
                <Normalize />
                <GlobalStyle />
                <Navigation />

                <Wrapper>{children}</Wrapper>
                <ToolBar style={{ transform: `translateX(${toolbarOpen ? 0 : '-100%'})` }}>
                    <Chip
                        active={true}
                        disabled={fontSize === 30}
                        handleClick={increaseFontSize}
                        id={'plus'}
                        label="＋ Zwiększ rozmiar czcionki"
                        noMargin={true}
                    />
                    <Chip
                        active={true}
                        disabled={fontSize === 16}
                        handleClick={decreaseFontSize}
                        id={'minus'}
                        label="−  Zmniejsz rozmiar czcionki"
                        noMargin={true}
                    />
                    {fontSize !== 16 && (
                        <Chip active={true} handleClick={resetFontSize} id={'plus'} label="Resetuj" noMargin={true} />
                    )}
                    <ToolBarControl onClick={handleToolBarOpen}>
                        <VisuallyHidden>Otwórz panel sterowania wyglądem strony</VisuallyHidden>
                        <FontSizeIcon style={{ marginTop: '5px' }} />
                    </ToolBarControl>
                </ToolBar>
                {toolbarOpen && <ToolBarCloseField onClick={(e) => setToolbarOpen(false)} />}
                <Footer data={data} />
            </LocaleProvider>
        </>
    );
};

export default Layout;

const ToolBar = styled.div`
    position: fixed;
    top: ${pxToRem(250)};
    padding: 20px;
    left: 0;
    background: #f6f6f6;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 2;
    box-shadow: 0px 2px 4px rgba(46, 41, 51, 0.08), 0px 4px 8px rgba(71, 63, 79, 0.16);
    transition: transform 400ms ease-in-out;
    //border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
`;

const ToolBarCloseField = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    z-index: 1;
`;

const ToolBarControl = styled.button`
    position: absolute;
    right: -49px;
    width: 50px;
    height: 50px;
    top: 0;
    background: #f6f6f6;
    z-index: 3;
    border: none;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    box-shadow: 0px 2px 4px rgba(46, 41, 51, 0.08), 0px 4px 8px rgba(71, 63, 79, 0.16);
    &:before {
        content: ' ';
        height: 50px;
        width: 10px;
        background: #f6f6f6;
        position: absolute;
        top: 0;
        left: -5px;
    }
`;

const Wrapper = styled.div`
    margin-top: ${pxToRem(178)};
    @media (max-width: ${pxToRem(1280)}) {
        margin-top: 8.125rem;
    }
    @media (max-width: ${pxToRem(1000)}) {
        margin-top: ${pxToRem(35)};
    }
`;
