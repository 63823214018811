import React, { FC } from 'react';
import styled from 'styled-components';
import { pxToRem } from '../utils/pxToRem';

interface Props {
    label: string;
    id: string;
    handleClick: (id: string) => void;
    active: boolean;
    noMargin?: boolean;
    disabled?: boolean;
}
export const Chip: FC<Props> = ({ label, id, handleClick, active, noMargin, disabled }) => {
    return (
        <StyledChip onClick={(e) => handleClick(id)} active={active} noMargin={noMargin} disabled={disabled}>
            {label}
        </StyledChip>
    );
};

const StyledChip = styled.button<{ active: boolean; noMargin?: boolean; disabled?: boolean }>`
    background-color: ${(props) => (props.active ? 'var(--color-primary)' : '#f6f6f6')};
    color: ${(props) => (props.active ? 'white' : 'var(--color-primary)')};
    border: none;
    padding: ${pxToRem(8)} ${pxToRem(12)};
    margin: ${(props) => (props.noMargin ? '0' : `${pxToRem(12)} ${pxToRem(12)} 0 0`)};
    cursor: pointer;
    border-radius: 20px;
    transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
    &:hover {
        background-color: var(--color-primary);
        color: white;
    }
    &:disabled {
        background-color: #ccc;
        color: var(--color-primary);
        cursor: auto;
    }
`;
