import React, {FC} from 'react';
import styled from 'styled-components';
import {pxToRem} from '../utils/pxToRem';
import {contentfulPageKontakt} from '../model/generated/graphql';
import {Container} from './Container/Container';
import FacebookIcon from '../../static/icons/facebook.svg';
import InstagramIcon from '../../static/icons/instagram.svg';
import {AccHide} from './accHide';
import {Script} from 'gatsby';

export const Footer: FC<{ data: { contentfulPageKontakt: contentfulPageKontakt } }> = ({ data }) => {
    return (
        <Wrapper>
            <Container>
                <Inner>
                    <div>MAGMED © {new Date().getFullYear()}</div>
                    <div>
                        {data.contentfulPageKontakt.facebookLink && data.contentfulPageKontakt.facebookLink !== ' ' && (
                            <a href={data.contentfulPageKontakt.facebookLink} target={'_blank'}>
                                <FacebookIcon width={48} fill="#fff" />
                                <AccHide>Facebook</AccHide>
                            </a>
                        )}
                        {data.contentfulPageKontakt.instagramLink && data.contentfulPageKontakt.instagramLink !== ' ' && (
                            <a href={data.contentfulPageKontakt.instagramLink} target={'_blank'}>
                                <InstagramIcon width={48} fill="#fff" />
                                <AccHide>Instagram</AccHide>
                            </a>
                        )}
                    </div>

                    <Script id="mydr" strategy="idle">
                        {`const mydrScript = document.getElementsByTagName('script')[0];
const js = document.createElement('script');
js.src = "https://mydr.pl/static/mydr-pp.min.js";
mydrScript.parentNode.insertBefore(js, mydrScript);
js.onload = () => {
const PatientsPlugin = new window.PatientsPlugin;
PatientsPlugin.init({
app: 'https://mydr.pl/patients_plugin',
plugin: 'https://mydr.pl/static',
});
};`}
                    </Script>
                </Inner>
            </Container>
        </Wrapper>
    );
};

const Inner = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Wrapper = styled.div`
    width: 100%;
    padding: ${pxToRem(40)};
    background-color: var(--color-primary);
    color: var(--color-white);
    margin-top: auto;
    box-sizing: border-box;
    text-align: center;
`;
