const React = require("react");
const { AnimatePresence } = require("framer-motion");
const Layout = require("./src/components/Layout/Layout").default;
const { StoreProvider } = require("./src/context/store");

const transitionDelay = 0;

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(() => window.scrollTo(...savedPosition), transitionDelay);
  }
  return false;
};

exports.wrapPageElement = ({ element, props }) => {
  return (
    <Layout {...props}>
      <StoreProvider>
        <AnimatePresence
          exitBeforeEnter
          onExitComplete={() => console.log("exit complete 1")}
          initial={false}
        >
          {element}
        </AnimatePresence>
      </StoreProvider>
    </Layout>
  );
};
