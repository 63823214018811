import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { pxToRem } from '../../utils/pxToRem';
import { rem } from 'polished';

interface Layout {
    [key: string]: any;
}
const StyledContainer = styled.div<Layout>`
    width: 100%;
    max-width: ${pxToRem(1280)};
    @media (max-width: ${rem(1400)}) {
        max-width: ${pxToRem(1024)};
    }
    padding: 0 ${pxToRem(24)};
    box-sizing: border-box;
    margin: 0 auto;

    @media (max-width: ${rem(480)}) {
        ${(p) =>
            p.mobile &&
            css`
                padding: 0;
            `}
    }

    ${(p) =>
        css`
            ${p.layout}
        `};
`;

type Props = {
    layout?: Layout;
    mobile?: boolean;
    children?: ReactNode;
};

export const Container: FC<Props> = ({ children, mobile, layout }) => (
    <StyledContainer layout={layout} mobile={mobile}>
        {children}
    </StyledContainer>
);
