import React, { FC, useState } from "react";

interface StoreContextI {
  callSetAnimate: (type: string) => void;
  animate: string;
}

export const StoreContext = React.createContext<StoreContextI>({
  animate: "",
  callSetAnimate: () => {},
});

export const StoreProvider: FC<StoreContextI> = ({ children }) => {
  const [animate, setAnimate] = useState("");

  const callSetAnimate = (type: string) => {
    setAnimate(type);
  };

  return (
    <StoreContext.Provider value={{ animate, callSetAnimate }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStoreContext = () => {
  const context = React.useContext(StoreContext);

  if (!context) {
    throw new Error(
      "StoreContext can only be used inside a corresponding provider"
    );
  }

  return context;
};
