import React, { FC, useContext } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import styled from "styled-components";
import { IMenuItem } from "../Navigation/Navigation";
import { MenuItem } from "../MenuItem";
import Logo from "../../../static/icons/logo-nav.svg";
import { Link } from "gatsby";
import { rem } from "polished";
import { useLocation } from "@reach/router";

const variants = {
  open: {
    display: "block",
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
    display: "none",
  },
};

const menu = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
};

type Props = {
  elements: IMenuItem[];
  closeModal: () => void;
};

export const MenuMobileContent: FC<Props> = ({ closeModal, elements }) => {
  // @ts-ignore
  // const { activeLocale, updateLocale } = useContext(LocaleContext);
  const { pathname } = useLocation();

  return (
    <NavWrapper variants={variants}>
      <Link to={"/"}>
        <Logo width={250} />
      </Link>
      <NavList>
        {elements.map((i) => (
          <li>
            <MenuItem
              item={i}
              key={i.slug}
              onClick={closeModal}
              active={pathname.includes(i.slug)}
            />
          </li>
        ))}
      </NavList>
    </NavWrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--color-white);
`;

const NavWrapper = styled(motion.div)`
  padding: ${rem(16)};
  padding-top: ${rem(40)};
  z-index: 103;
  list-style: none;
  width: 100%;
`;

const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;

  a {
    font-size: 1.5rem;
    line-height: 2;
  }
`;

const itemIds = [
  {
    name: "home",
    slug: "/",
  },
  {
    name: "movies",
    slug: "/movies/the-three-kings",
  },
  {
    name: "team",
    slug: "/team/",
  },
];
