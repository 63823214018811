import React, {FC} from 'react';
import {motion, useCycle} from 'framer-motion';
import {MenuToggle} from './MenuToggle';
import {MenuMobileContent} from './MenuMobileContent';
import Modal from 'react-modal';
import styled from 'styled-components';
import {IMenuItem} from '../Navigation/Navigation';
import {rem} from 'polished';

type Props = {
  open: boolean;
  itemIds: IMenuItem[];
};

export const MenuMobile: FC<Props> = ({ open, itemIds }) => {
  const [isOpen, toggleOpen] = useCycle(false, true);

  return (
    <Wrapper>
      <motion.div initial={false} animate={isOpen ? 'open' : 'closed'}>
        <TriggerWrapper>
          <MenuToggle
            toggle={() => {
              toggleOpen();
            }}
          />
        </TriggerWrapper>
      </motion.div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => toggleOpen()}
        contentLabel="Menu modal"
        className="_"
        overlayClassName="_"
        closeTimeoutMS={500}
        contentElement={(props, children) => <ModalStyle {...props}>{children}</ModalStyle>}
        overlayElement={(props, contentElement) => <OverlayStyle {...props}>{contentElement}</OverlayStyle>}
      >
        <MenuMobileContent closeModal={toggleOpen} elements={itemIds} />
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  @media (min-width: ${rem(1000)}) {
    display: none;
  }
`;

const menu = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
};

const TriggerWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
`;

const ModalStyle = styled.div`
  background-color: var(--color-white);
  color: var(--color-primary);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const OverlayStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
