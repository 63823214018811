import * as React from 'react';
import {FC, useContext} from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';
import LocaleContext from '../context/Locale';
import {pxToRem} from '../utils/pxToRem';
import {IMenuItem} from './Navigation/Navigation';

type Props = {
  item: IMenuItem;
  onClick?: () => void;
  active: boolean;
};

export const MenuItem: FC<Props> = ({ item, onClick, active }) => {
  const { name, slug } = item;
  const { activeLocale } = useContext(LocaleContext);

  return (
    <StyledLink to={`/${activeLocale === 'en' ? 'en' : 'pl'}${slug}`} onClick={onClick} theme={active ? 'active' : ''}>
      {name}
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  font-size: 1rem;
  color: var(
    ${(props) => {
      return props.theme === 'active' ? '--color-text-secondary' : '--color-text-primary';
    }}
  );
  line-height: 1.65;
  font-weight: 700;
  padding: ${pxToRem(6)} ${pxToRem(24)};
  text-transform: uppercase;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  &:hover {
    color: var(--color-secondary);
  }
`;
